<template>
  <div>
    <v-row v-if="previewEditor===null">
      <v-col align="center">
        <v-progress-circular
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-if="previewEditor!==null">
      <v-col>
        <editor-content :editor="previewEditor"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Editor, EditorContent} from '@tiptap/vue-2'
//import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import History from '@tiptap/extension-history'
import TextAlign from '@tiptap/extension-text-align'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import {Indent} from "@/wysiwyg/indent"
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import {Color} from '@tiptap/extension-color'
import Blockquote from '@tiptap/extension-blockquote'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Link from '@tiptap/extension-link'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Gapcursor from '@tiptap/extension-gapcursor'
import Heading from '@tiptap/extension-heading'


import ExtensionEditForm from "@/components/wysiwyg/ExtensionEditForm";
//import { generateHTML } from '@tiptap/core'


const defaultFormModel = {
  vorname: {
    label: "Vorname",
    required: true,
    rules: "notEmptyRules",
    type: 'text'
  },
  nachname: {
    label: "Nachname",
    required: true,
    rules: "notEmptyRules",
    type: 'text'
  },
  strasse: "Straße",
  hausnummer: "Hausnummer"
};

const extensions = [
  //StarterKit,
  Document,
  Paragraph,
  Heading.configure({
    levels: [1, 2, 3],
  }),
  History,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Highlight.configure({multicolor: true}),
  Underline,
  BulletList,
  ListItem,
  OrderedList,
  Indent.configure({
    types: ['listItem', 'paragraph'],
    minLevel: 0,
    maxLevel: 8,
  }),
  Image,
  Dropcursor,
  Color,
  Text,
  TextStyle,
  Blockquote,
  HorizontalRule.configure({
    HTMLAttributes: {
      class: 'v-divider',
    },
  }),
  Subscript,
  Superscript,
  Link,
  Gapcursor,
  Table.configure({
    resizable: false,
  }),
  TableRow,
  TableHeader,
  TableCell
];


export default {


  components: {
    EditorContent
  },

  props: {
    noForm: {
      type: Boolean,
      required: false
    },
    wysiwygContent: {
      type: [String, Object],
      default: '',
    },
    campaign: {
      type: [Object],
      required: false
    },
    record: {
      type: [Object],
      required: false
    },
    campaignUptadeObject: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      previewEditor: null,
    }
  },
  mounted() {

    if (!this.$props.noForm) {
      extensions.push(ExtensionEditForm.configure({
        HTMLAttributes: {
          model: this.$props.wysiwygContent !== null && typeof this.$props.wysiwygContent === 'object' && this.$props.wysiwygContent.formModel ? this.$props.wysiwygContent.formModel : defaultFormModel,
          campaign: this.$props.campaign,
          record: this.$props.record,
          reactionHandler: (finish) => {
            finish
                ?
                this.$emit('success')
                :
                this.$emit('close')
          },
          campaignUptadeObject: this.$props.campaignUptadeObject
        }
      }));
    }
    //check if form model is defined, otherwise, load default model.


    this.previewEditor = new Editor({
      content: this.wysiwygContent,
      editable: false,
      extensions: extensions
    })
  },
  beforeDestroy() {
    this.previewEditor && this.previewEditor.destroy ? this.previewEditor.destroy() : '';
  }
}
</script>
<style>
.ProseMirror:focus-visible {
  border: 0px solid black;
  outline-color: transparent;
  outline: none;
}


</style>

<style lang="scss">
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: var(--v-primary-base);
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>